import React from 'react'
import styled from 'styled-components'

import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import Menu from '../../components/Menu'
import Contact from '../../components/Contact'

const Section = styled.section`
  width: 100%;
  height: 400px;
  margin: 0 auto;
  text-align: center;
`

const SuccessPage = () => (
  <Layout>
    <SEO title='Contact form ကို အောင်မြင်စွာ လက်ခံရရှိပါသည်။ | Contact form submitted successfully' />
    <Menu />
    <Section>
      <h1>Thank you!</h1>
      <p>Thank you for contacting us. We will get back to you soon.</p>
      <p>
        အခုလို ဆက်သွယ်တာ ကျေးဇူးတင်ပါတယ်။
        <br />
        <br />
        အီးမေးလ်မှ တစ်ဆင့် ကျွန်တော်တို့ အမြန်ဆုံးပြန်ဆက်သွယ်ပါ့မယ်။
      </p>
    </Section>
    <Contact />
  </Layout>
)

export default SuccessPage
